import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

// Import utilties
import { customMinResponsive, size } from 'components/styles';

// Import images
import { ReactComponent as Logo } from 'assets/icons/redcarpet_logo.svg';

export const FooterStyled = styled.footer`
	position: relative;
	background-color: ${({ theme: { body } }) => body.footer.bg};
	color: ${({ theme: { body } }) => body.footer.color};
	${flex({ fd: 'column' })}
`;

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	padding: 4rem;
	margin: 0 auto;
	${flex({ jc: 'space-between', ai: 'flex-start', fd: 'column' })}

	${customMinResponsive(
		size.large,
		css`
			padding: 2.5rem 4rem;
		`
	)}

	${customMinResponsive(
		1600,
		css`
			padding: 2.5rem 8rem;
		`
	)}
`;

export const NavWrapper = styled.div`
	display: flex;
	margin: 0 auto;

	${customMinResponsive(
		size.medium,
		css`
			margin: 0;
			align-items: center;
			gap: 1rem;
		`
	)}

	${customMinResponsive(
		size.large,
		css`
			gap: 8rem;
		`
	)}
`;

export const StyledLogo = styled(Logo)`
	width: 9.1rem;
	height: 8.5rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 8rem;
			height: 5.8rem;
		`
	)}
`;

export const FlexContainer = styled.div`
	width: 100%;
	${flex({ ai: 'flex-start' })}
	flex-direction: ${({ flexDirection }) =>
		flexDirection ? flexDirection : 'column'};

	${customMinResponsive(
		size.medium,
		css`
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		`
	)}
`;

export const InnerContainer = styled.div`
	${flex({ jc: 'flex-start' })}
	flex-wrap: wrap;
	margin: 0 auto;

	${customMinResponsive(
		size.medium,
		css`
			${flex({ fd: 'row', jc: 'flex-end' })}
		`
	)};

	${customMinResponsive(
		1400,
		css`
			margin: 0;
			row-gap: 2.8rem;
			flex-wrap: nowrap;
		`
	)};

	& > :not(:last-child):after {
		display: none;
		content: '';

		${customMinResponsive(
			1400,
			css`
				display: block;
				height: 2.8rem;
				width: 0.1rem;
				background-color: ${({ theme }) => theme.body.footer.separatingLine};
				margin: 0 3rem 0 1rem;
			`
		)}
	}
`;

export const AppVersion = styled.span`
	color: ${({ theme }) => theme.body.footer.color};
	font-size: ${({ theme }) => theme.fontSize.footer.media.text.mobile}rem;
	font-weight: ${({ theme }) => theme.font.light};
	width: 100%;
	padding: 0 4rem;
	text-align: center;
	line-height: 2.4rem;

	${customMinResponsive(
		size.medium,
		css`
			text-align: right;
		`
	)}

	${customMinResponsive(
		1600,
		css`
			padding: 0 8rem;
		`
	)}
`;

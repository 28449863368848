import React from 'react';
import { useTranslation } from 'react-i18next';
import { oneOfType, arrayOf, node } from 'prop-types';
import { useSelector } from 'react-redux';

// Import Context Provider
import AuthenticationProvider from 'components/context/authentication/AuthenticationProvider';

// Import styled Components
import {
	AuthenticationWrapper,
	LogoContainer,
	AuthenticationContainer,
	AuthenticationBox,
	StyledLogo
} from './styles';

// Import helpers
import Logo from 'assets/icons/redcarpet_logo.svg';

const AuthenticationTemplate = ({ children }) => {
	const { t } = useTranslation();
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	return (
		<AuthenticationProvider>
			<AuthenticationWrapper>
				<LogoContainer>
					<StyledLogo
						aria-hidden="true"
						title={t('common_portal_logo_title')}
						isContrast={isContrast}
						src={Logo}
					/>
				</LogoContainer>
				<AuthenticationContainer>
					<AuthenticationBox>{children}</AuthenticationBox>
				</AuthenticationContainer>
			</AuthenticationWrapper>
		</AuthenticationProvider>
	);
};

AuthenticationTemplate.propTypes = {
	children: oneOfType([arrayOf(node), node])
};

export default AuthenticationTemplate;
